import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import styled from 'styled-components'
import SEO from '../components/seo'
import { Container } from './review'

const SnapFrameSetUp = () => {
  const { snapFrame } = useStaticQuery(graphql`
    query SnapFrameSetUpQuery {
      snapFrame: file(relativePath: { eq: "frame2/swappable-frame.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Changing Out Your Snap Frame | Skylight Frame"
        path="/snapframesetup/"
        description="Watch step by step instructions for how to remove and change out your Snap Frames for your Frame 2 device."
        imagePath={snapFrame.childImageSharp.gatsbyImageData.images.fallback.src}
      />
      <Container>
        <Video playsInline autoPlay muted loop controls={false}>
          <source
            src="https://skylight-marketing-assets.s3.amazonaws.com/SkylightSnapFrameHowTo.mov"
            type="video/mp4"
          />
        </Video>
      </Container>
    </>
  )
}

export default SnapFrameSetUp
export const Video = styled.video`
  width: 350px;
  max-width: 100%;
`
