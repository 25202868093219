import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { darken, lighten } from 'polished'

import { breakpoints, ContentContainer, TwoColumnGrid } from 'src/utils/styles/global-styles'

export const TrialEnding = styled.div`
  max-width: 900px;
  margin: auto;
  margin-bottom: 80px;
`

export const List = styled.div`
  margin: 0px 24px;
`

export const ListItem = styled.li``

const ListItemStyles = css`
  line-height: 24px;
  letter-spacing: 0.09px;
  margin: 10px auto;
  font-size: 16px;
  font-family: 'FilsonPro';
  span:first-of-type {
    font-family: 'FilsonProMedium';
  }
`

export const BasicListItem = styled.p`
  ${ListItemStyles}
  color: ${(props) => props.theme.grayDark};
  &:not(:first-child) {
    text-decoration: line-through;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding-bottom: 16px;
    border-bottom: solid 1.5px rgb(68, 68, 68, 0.1);
    &:not(:first-child) {
      display: none;
    }
  }
`
export const PlusListItem = styled.p`
  ${ListItemStyles}
  color: ${(props) => props.theme.blueDark};
`
export const ListHeaderContainer = styled.section`
  margin: 24px;
`

export const ListHeader = styled.h2`
  color: ${(props) => props.color};
  font-size: 35px;
  line-height: 49px;
  letter-spacing: 0.18px;
  margin: 0;
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15px;
  }
  @media (max-width: 360px) {
    font-size: 26px;
  }
`

export const ListSubHeader = styled.p`
  font-family: 'FilsonPro';
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  display: 'inline';
`

export const ListHeaderText = styled.p`
  font-family: 'FilsonPro';
  color: ${(props) => props.theme.grayDark};
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.08px;
  margin: 0px 0px 16px;
  @media (max-width: ${breakpoints.l}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'inline')};
  }
`

export const ButtonContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0px;
`

export const GetBasicButton = styled(Link)`
  text-decoration: none;
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  padding: 16px 43px;
  border-radius: 28px;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => darken(0.2, props.color)};
  }
  &:focus {
    outline: none;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.3, props.color)}`};
  }
  font-family: 'FilsonPro';
  line-height: 23px;
  letter-spacing: 0.09px;
  font-size: 16px;
`

export const TierBox = styled.div`
  position: relative;
  background-color: #ffffff;
  border: solid 1.5px #e8e5d2;
  margin: auto;
  max-width: 700px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  @media (max-width: ${breakpoints.l}px) {
    min-height: inherit;
    margin-bottom: 20px;
    height: auto;
    > .basic > hr {
      display: none;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 40px;
  }
`

export const TierStripe = styled.div`
  height: 65px;
  width: 100%;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  @media (max-width: ${breakpoints.s}px) {
    height: 50px;
  }
`

export const Recommended = styled.p`
  font-family: 'FilsonPro';
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  margin: 24px 24px auto;
  @media (max-width: ${breakpoints.s}px) {
    margin: 20px 24px 10px;
  }
`
export const Header = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: 0.19px;
  text-align: center;
  margin: 0px auto 20px auto;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: ${breakpoints.m}px) {
    font-size: 34px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 0.15px;
    margin: 0px auto 32px auto;
  }
`
export const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
  padding: 0px 0px 10px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 16px 0px;
  }
`
export const Container = styled(ContentContainer)`
  margin-top: 0px;
  width: 1400px;
  @media (max-width: ${breakpoints.s}px) {
    margin-bottom: 0px;
  }
`
export const Line = styled.hr`
  border: solid 1px #ececec;
`
export const FlexBox = styled.div`
  display: flex;
  margin: 0px;
  align-items: flex-end;
`
export const TextGray = styled.span`
  ${ListItemStyles}
  color: ${(props) => props.theme.grayDark};
`
export const GridContainer = styled(TwoColumnGrid)`
  @media (max-width: ${breakpoints.l}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`
